<template>
  <div
    class="card-table mb-5"
    :class="mode ? 'cardtable__day' : 'cardtable__night'"
  >
    <div class="workers-info-title-za">
      <div class="title">{{ $t("message.explanatoryLetter") }}</div>
    </div>

    <div class="m-3">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="refa__chart">
            <el-card
              class="box-card bg-light-primary"
              :class="mode ? '' : 'text-warning__night'"
            >
              <i class="my-icon el-icon-tickets text-primary"></i>
              <div class="mobile__izn">
                <div class="text-center">
                  <span class="text-primary">
                    {{ $t("message.total_qty") }}
                  </span>
                </div>
                <div class="text-center mt-3">
                  <b class="text-primary" v-if="explanatory_letters">{{
                    explanatory_letters.length
                  }}</b>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="scrol-table crmhome__scroll">
            <table
              :class="mode ? 'table__myday' : 'table__mynight'"
              class="
                                    table__layout
                                    table-my-code table-bordered
                                    my-table-card my-clendar
                                "
            >
              <thead>
                <tr>
                  <th class="w50p" scope="col">{{ $t("message.n") }}</th>
                  <th scope="col">{{ $t("message.employees") }}</th>
                  <th scope="col">{{ $t("message.date_time") }}</th>
                  <th scope="col">{{ $t("message.comment") }}</th>
                  <th scope="col">{{ $t("message.performance_type") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(additional, index) in explanatory_letters"
                  :key="'additional-' + index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      additional.staff
                        ? additional.staff.name +
                          " " +
                          additional.staff.last_name
                        : ""
                    }}
                  </td>
                  <td>
                    {{ additional.created_at }}
                  </td>

                  <td>{{ additional.comment }}</td>
                  <td>
                    <div class="image__preview application_img">
                      <el-image
                        class="my-c-avatar"
                        style="width: 50px; height: 50px"
                        :src="
                          additional.image
                            ? baseUrl + additional.image
                            : 'img/avatar.jpeg'
                        "
                        :preview-src-list="[
                          additional.image
                            ? baseUrl + additional.image
                            : 'img/avatar.jpeg'
                        ]"
                      >
                      </el-image>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "explanatory-letter",
  props: {
    staff_id: {
      type: Number
    }
  },

  data() {
    return {
      explanatory_letters: {},
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      )
    };
  },
  mounted() {
    this.table();
  },
  computed: {
    ...mapGetters({
      list: "explanatoryLetter/list",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "explanatoryLetter/explanatoryLetter"
    }),

    table() {
      const query = { staff_id: this.staff_id };
      this.updateList(query).then(res => {
        this.explanatory_letters = res.data.result.data.explanatory_letters;
      });
    }
  }
};
</script>

<style lang="scss">
.application_img {
  .my-c-avatar {
    height: 80px !important;
    width: 70px !important;
  }

  .my-c-avatar img {
    height: 80px !important;
    border-radius: 0;
  }
}
</style>
