<template>
  <div v-can="'profile.requirements.index'">
    <div
      class="card-table mb-5"
      :class="mode ? 'cardtable__day' : 'cardtable__night'"
    >
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.requirements") }}</div>
        <i
          class="el-icon-plus"
          @click="ClientRequirementsCreate = true"
          v-can="'profile.requirements.create'"
        ></i>
      </div>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="loadClientRequirements"
      >
        <table class="w-100 td20">
          <tr>
            <td colspan="2">
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.nameGiven") }}
              </strong>
            </td>
          </tr>

          <tr
            v-for="(client_requirement, index) in staffClientRequirements"
            :key="'client_requirement-' + index"
          >
            <td>
              <p
                class="font-medium mb-3 mt-3"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                <span
                  v-for="(require, index) in client_requirement.requirements"
                  :key="'require' + index"
                >
                  <p>{{ require.name }}</p>
                  <pre
                    style="font-family: 'M-Regular'; font-size: 14px !important; line-height: 1.5; white-space: break-spaces;     margin-block-start: 1em;
                    margin-block-end: 1em; margin-inline-start: 0px;
                    margin-inline-end: 0px;"
                    v-html="require.description"
                    >{{ require.description }}</pre
                  >
                </span>
              </p>
            </td>

            <td class="text-right">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editStaffClientRequirement(client_requirement)"
                  v-can="'profile.requirements.edit'"
                ></el-button>

                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteClientRequirement(client_requirement)"
                  v-can="'profile.requirements.destroy'"
                ></el-button>
              </el-row>

              <span class="is_show">
                <el-alert
                  v-if="staff.show_requirement"
                  title="Tanishib chiqildi"
                  type="success"
                  effect="dark"
                  center
                  :closable="false"
                  show-icon
                >
                </el-alert>
                <el-alert
                  v-else
                  title="Tanishib chiqilmadi"
                  type="error"
                  effect="dark"
                  center
                  :closable="false"
                  show-icon
                >
                </el-alert>
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- start Modal  -->
    <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      title="Требования"
      :visible.sync="ClientRequirementsCreate"
      width="60%"
      center
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              class="input--label d-block mb-2"
            >
              {{ $t("message.requirements") }}
            </span>
            <el-form-item prop="requirement_ids">
              <select-requirement
                :id="form.requirement_ids"
                :size="'medium'"
                v-model="form.requirement_ids"
                :multiple="true"
              >
              </select-requirement>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="submit()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      title="Изменить Требования"
      :visible.sync="ClientRequirementsUpdate"
      width="60%"
      center
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              class="input--label d-block mb-2"
            >
              {{ $t("message.requirements") }}
            </span>
            <el-form-item prop="requirement_ids">
              <select-requirement
                :id="form.requirement_ids"
                :size="'medium'"
                v-model="form.requirement_ids"
                :multiple="true"
              >
              </select-requirement>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="update()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
import selectRequirement from "@/components/filters/inventory/select-requirement";
export default {
  components: {
    selectRequirement
  },
  data() {
    return {
      form: {},
      loadClientRequirements: false,
      ClientRequirementsCreate: false,
      ClientRequirementsUpdate: false
    };
  },
  props: {
    staff: {
      type: Object,
      required: true
    }
  },
  watch: {
    "form.requirement_ids": {
      handler: function() {},
      deep: true
    }
  },
  created() {
    this.fetchStaffClientRequirements();
  },
  computed: {
    ...mapGetters({
      staffClientRequirements: "profile/staff_client_requirement",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      getStaffClientRequirements: "profile/getStaffRequirement",
      addStaffClientRequirements: "profile/addStaffRequirement",
      uploadStaffClientRequirements: "profile/updateStaffRequirement",
      deleteStaffClientRequirements: "profile/deleteStaffRequirement"
    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      if (this.form.staff_id) {
        this.addStaffClientRequirements(this.form)
          .then(res => {
            this.$alert(res);
            if (res.status == 201) {
              this.fetchStaffClientRequirements();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
          });
      }
    },

    fetchStaffClientRequirements() {
      if (!this.loadClientRequirements && this.$route.params.id) {
        this.loadClientRequirements = true;
        this.getStaffClientRequirements(this.$route.params.id)
          .then(res => {
            this.loadClientRequirements = false;
          })
          .catch(err => {
            this.loadClientRequirements = false;
          });
      }
    },
    close() {
      this.clearForm();
      this.ClientRequirementsCreate = false;
      this.ClientRequirementsUpdate = false;
    },
    clearForm() {
      for (var key in this.form) {
        this.form[key] = null;
      }
    },
    editStaffClientRequirement(client_requirement) {
      let requirement_ids = [];
      for (let key in client_requirement.requirements) {
        requirement_ids.push(client_requirement.requirements[key].id);
      }
      this.form = JSON.parse(JSON.stringify(client_requirement));
      this.form.requirement_ids = requirement_ids;
      this.ClientRequirementsUpdate = true;
    },
    opened(refs) {
      this.$refs[refs].opened();
    },
    open(client_requirement) {
      this.form = JSON.parse(JSON.stringify(client_requirement));
      this.ClientRequirementsUpdate = true;
    },
    update() {
      this.form.staff_id = this.$route.params.id;
      this.form.client_requirement_id = this.form.id;
      if (this.form.staff_id && this.form.client_requirement_id) {
        this.loadClientRequirements = true;
        this.uploadStaffClientRequirements(this.form)
          .then(res => {
            this.$alert(res);
            this.loadClientRequirements = false;
            if (res.status == 201) {
              this.clearForm();
              this.fetchStaffClientRequirements();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
            this.loadClientRequirements = false;
          });
      }
    },
    deleteClientRequirement(client_requirement) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning"
      })
        .then(() => {
          if (!this.loadClientRequirements && this.$route.params.id) {
            this.loadClientRequirements = true;
            let form = {
              staff_id: this.$route.params.id,
              client_requirement_id: client_requirement.id
            };
            console.log(form);
            this.deleteStaffClientRequirements(form)
              .then(res => {
                this.loadClientRequirements = false;
                this.$alert(res);
                if (res.status == 200) {
                  this.fetchStaffClientRequirements();
                }
              })
              .catch(err => {
                this.loadClientRequirements = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена"
          });
        });
    }
  }
};
</script>
<style lang="scss">
.is_show {
  position: absolute;
  max-width: 250px;
  right: 30px;
  bottom: 60px;
  overflow: hidden;
}
</style>
