<template>
    <div>
        <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title"> {{$t("message.place_work")}}</div>
                <i class="el-icon-plus" @click="workPlacesCreate = true" v-can="'profile.workplace.create'"></i>
            </div>
            <div
                class="workers-info madal-info-workers p-5 pt-2 pb-2"
                v-loading="loadWorkPlaces"
            >
                <table class="w-100 td20" :class="mode ? 'table__myday' : 'table__mynight'">
                    <tr>
                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"  >
                                 {{$t("message.place_work_name")}}
                            </strong>
                        </td>

                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"  >  {{$t("message.position")}} </strong>
                        </td>

                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"  >
                                {{$t("message.manager")}}
                            </strong>
                        </td>

                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"  >  {{$t("message.allow_salary")}} </strong>
                        </td>

                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"  >
                                {{$t("message.data_from_to")}}</strong
                            >
                        </td>

                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"  > {{$t("message.notes")}} </strong>
                        </td>
                    </tr>

                    <tr
                        v-for="(work_place, index) in staffWorkPlaces"
                        :key="'work_place-' + index"
                    >
                        <td>
                            <p class="font-medium mb-3 mt-3">
                                <span> {{ work_place.work_place }} </span>
                            </p>
                        </td>

                        <td>
                            <p class="font-medium mb-3 mt-3">
                                <span> {{ work_place.position_name }} </span>
                            </p>
                        </td>

                        <td>
                            <p class="font-medium mb-3 mt-3">
                                <span>
                                    {{ work_place.supervisor_full_name }}
                                </span>
                            </p>
                        </td>

                        <td>
                            <p class="font-medium mb-3 mt-3">
                                <span>
                                    {{ work_place.salary }}
                                </span>
                            </p>
                        </td>

                        <td>
                            <p class="font-medium mb-3 mt-3">
                                <span>
                                    {{ work_place.started_date }}
                                    <span></span>
                                    <span></span>
                                    {{ work_place.finished_date }}
                                </span>
                            </p>
                        </td>

                        <td>
                            <p class="font-medium mb-3 mt-3">
                                <span>
                                    {{ work_place.reason }}
                                </span>
                            </p>
                        </td>

                        <td class="text-right">
                            <el-row>
                                <el-button
                                    type="primary"
                                    icon="el-icon-edit"
                                    circle
                                    size="small"
                                    @click="editStaffWorkPlace(work_place)"
                                    v-can="'profile.workplace.edit'"
                                ></el-button>

                                <el-button
                                    type="danger"
                                    icon="el-icon-delete"
                                    circle
                                    size="small"
                                    @click="deleteWorkPlace(work_place)"
                                    v-can="'profile.workplace.destroy'"
                                ></el-button>
                            </el-row>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <!-- start Modal  -->
        <el-dialog
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
            class="ichki_modal"
            title="Места работы"
            :visible.sync="workPlacesCreate"
            width="60%"
            center
        >
            <el-form ref="form" :model="form">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t('message.place_work_name')}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                v-model="form.work_place"
                                :placeholder="$t('message.place_work_name')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t('message.position')}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                v-model="form.position_name"
                                :placeholder="$t('message.position')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t('message.manager')}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                v-model="form.supervisor_full_name"
                                :placeholder="$t('message.manager')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t('message.allow_salary')}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                v-model="form.salary"
                                :placeholder="$t('message.allow_salary')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t('message.from_data')}}
                            </span>
                            <crm-date-picker
                            :class="mode ? 'input__day' : 'input__night'"
                                :date="form.started_date"
                                v-model="form.started_date"
                                type="date"
                                :placeholder="$t('message.from_data')"
                                :size="'medium'"
                            >
                            </crm-date-picker>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{ $t('message.to_data')}}
                            </span>
                            <crm-date-picker
                            :class="mode ? 'input__day' : 'input__night'"
                                :date="form.finished_date"
                                v-model="form.finished_date"
                                type="date"
                                :placeholder="$t('message.to_data')"
                                :size="'medium'"
                            >
                            </crm-date-picker>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{$t('message.notes')}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                v-model="form.reason"
                                :placeholder="$t('message.notes')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="submit()" >
                    {{$t("message.save")}}
                </el-button>
                <el-button type="warning" size="medium" @click="close()" >
                    {{$t("message.close")}}
                </el-button>
            </span>
        </el-dialog>

        <el-dialog
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
            class="ichki_modal"
            title="Добавить Образование"
            :visible.sync="workPlacesUpdate"
            width="60%"
            center
        >
            <el-form ref="form" :model="form">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t("message.place_work_name")}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                :inputValue="form.work_place"
                                v-model="form.work_place"
                                :placeholder="$t('message.place_work_name')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t("message.position")}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                :inputValue="form.position_name"
                                v-model="form.position_name"
                                :placeholder="$t('message.position')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t("message.manager")}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :size="'medium'"
                                :inputValue="form.supervisor_full_name"
                                v-model="form.supervisor_full_name"
                                :placeholder="$t('message.manager')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t("message.allow_salary")}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="form.salary"
                                :size="'medium'"
                                v-model="form.salary"
                                :placeholder="$t('message.allow_salary')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                               {{$t('message.from_data')}}
                            </span>
                            <crm-date-picker
                            :class="mode ? 'input__day' : 'input__night'"
                                :date="form.started_date"
                                v-model="form.started_date"
                                type="date"
                                :placeholder="$t('message.from_data')"
                                :size="'medium'"
                            >
                            </crm-date-picker>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{$t('message.to_data')}}
                            </span>
                            <crm-date-picker
                            :class="mode ? 'input__day' : 'input__night'"
                                :date="form.finished_date"
                                v-model="form.finished_date"
                                type="date"
                                :placeholder="$t('message.to_data')"
                                :size="'medium'"
                            >
                            </crm-date-picker>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{$t("message.reason")}}
                            </span>
                            <crm-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="form.reason"
                                :size="'medium'"
                                v-model="form.reason"
                                :placeholder="$t('message.reason')"
                            ></crm-input>
                        </div>
                    </el-col>
                    <!-- end col -->
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="update()" >
                    {{$t("message.save")}}
                </el-button>
                <el-button type="warning" size="medium" @click="close()" >
                    {{$t("message.close")}}
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
    components: {},
    data() {
        return {
            form: {},
            loadWorkPlaces: false,
            workPlacesCreate: false,
            workPlacesUpdate: false,
        };
    },
    created() {
        this.fetchStaffWorkPlaces();
    },
    computed: {
        ...mapGetters({
            staffWorkPlaces: "profile/staff_work_places",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            getStaffWorkPlaces: "profile/getStaffWorkPlaces",
            addStaffWorkPlaces: "profile/addStaffWorkPlaces",
            uploadStaffWorkPlaces: "profile/uploadStaffWorkPlaces",
            deleteStaffWorkPlaces: "profile/deleteStaffWorkPlaces",
        }),
        submit() {
            this.form.staff_id = this.$route.params.id;
            if (this.form.staff_id) {
                this.addStaffWorkPlaces(this.form)
                    .then((res) => {
                        this.$alert(res);
                        if (res.status == 201) {
                            this.clearForm();
                            this.fetchStaffWorkPlaces();
                            this.close();
                        }
                    })
                    .catch((err) => {
                        this.$alert(err);
                    });
            }
        },

        fetchStaffWorkPlaces() {
            if (!this.loadWorkPlaces && this.$route.params.id) {
                this.loadWorkPlaces = true;
                this.getStaffWorkPlaces(this.$route.params.id)
                    .then((res) => {
                        this.loadWorkPlaces = false;
                    })
                    .catch((err) => {
                        this.loadWorkPlaces = false;
                    });
            }
        },
        close() {
            this.clearForm();
            this.workPlacesCreate = false;
            this.workPlacesUpdate = false;
        },
        clearForm() {
            for (var key in this.form) {
                this.form[key] = null;
            }
        },
        editStaffWorkPlace(work_place) {
            this.form = JSON.parse(JSON.stringify(work_place));
            this.workPlacesUpdate = true;
        },
        opened(refs) {
            this.$refs[refs].opened();
        },
        open(work_place) {
            this.form = JSON.parse(JSON.stringify(work_place));
            this.workPlacesUpdate = true;
        },
        update() {
            this.form.staff_id = this.$route.params.id;
            this.form.work_place_id = this.form.id;
            if (this.form.staff_id && this.form.work_place_id) {
                this.loadWorkPlaces = true;
                this.uploadStaffWorkPlaces(this.form)
                    .then((res) => {
                        this.$alert(res);
                        this.loadWorkPlaces = false;
                        if (res.status == 201) {
                            this.clearForm();
                            this.fetchStaffWorkPlaces();
                            this.close();
                        }
                    })
                    .catch((err) => {
                        this.$alert(err);
                        this.loadWorkPlaces = false;
                    });
            }
        },
        deleteWorkPlace(work_place) {
            this.$confirm(
                "Вы действительно хотите это сделать?",
                "Предупреждение",
                {
                    confirmButtonText: "Да",
                    cancelButtonText: "Отмен",
                    type: "warning",
                }
            )
                .then(() => {
                    if (!this.loadWorkPlaces && this.$route.params.id) {
                        this.loadWorkPlaces = true;
                        let form = {
                            staff_id: this.$route.params.id,
                            work_place_id: work_place.id,
                        };
                        this.deleteStaffWorkPlaces(form)
                            .then((res) => {
                                this.loadWorkPlaces = false;
                                this.$alert(res);
                                if (res.status == 200) {
                                    this.fetchStaffWorkPlaces();
                                }
                            })
                            .catch((err) => {
                                this.loadWorkPlaces = false;
                            });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "Операция отменена",
                    });
                });
        },
    },
};
</script>

