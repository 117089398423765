<template>
  <div
    class="card-table mb-5"
    :class="mode ? 'cardtable__day' : 'cardtable__night'"
  >
    <div class="workers-info-title-za">
      <div class="title">{{ $t("message.application") }}</div>
    </div>

    <div class="m-3">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="refa__chart">
            <el-card
              class="box-card bg-light-primary"
              :class="mode ? '' : 'text-warning__night'"
            >
              <i class="my-icon el-icon-tickets text-primary"></i>
              <div class="mobile__izn">
                <div class="text-center">
                  <span class="text-primary">
                    {{ $t("message.total_qty") }}
                  </span>
                </div>
                <div class="text-center mt-3">
                  <b class="text-primary" v-if="applications">{{
                    applications.length
                  }}</b>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="scrol-table crmhome__scroll">
            <table
              :class="mode ? 'table__myday' : 'table__mynight'"
              class="
                                    table__layout
                                    table-my-code table-bordered
                                    my-table-card my-clendar
                                "
            >
              <thead>
                <tr>
                  <th class="w50p" scope="col">{{ $t("message.n") }}</th>
                  <th scope="col">{{ $t("message.employees") }}</th>
                  <th scope="col">{{ $t("message.type") }}</th>
                  <th scope="col">{{ $t("message.datas") }}</th>
                  <th scope="col">{{ $t("message.comment") }}</th>
                  <th scope="col">{{ $t("message.performance_type") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(additional, index) in applications"
                  :key="'additional-' + index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      additional.staff
                        ? additional.staff.name +
                          " " +
                          additional.staff.last_name
                        : ""
                    }}
                  </td>
                  <td>
                    {{ additional.type?.name }}
                  </td>
                  <td>
                    {{ additional.date }}
                  </td>
                  <td>{{ additional.comment }}</td>
                  <td>
                    <el-button
                      type="success"
                      size="small"
                      @click="printDoc(additional.id)"
                      class="mr-2"
                    >
                      <i class="el-icon-printer"></i>
                    </el-button>
                    <a
                      :href="
                        baseUrl +
                          'api/staffs/download-application/' +
                          additional.id
                      "
                    >
                      <el-button type="primary" size="small">
                        <i class="el-icon-download"></i>
                      </el-button>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import $ from "jquery";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "explanatory-letter",
  props: {
    staff_id: {
      type: Number
    }
  },

  data() {
    return {
      applications: {},
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      )
    };
  },
  mounted() {
    this.table();
  },
  computed: {
    ...mapGetters({
      list: "application/list",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "application/application"
    }),

    table() {
      const query = { staff_id: this.staff_id };
      this.updateList(query).then(res => {
        this.applications = res.data.result.data.applications;
      });
    },
    printDoc(id) {
      this.waitingPrint = true;
      let vm = this;

      var jqxhr = $.get(
        process.env.VUE_APP_URL + `/staffs/print-application/${id}`,
        function() {}
      )
        .done(function(res) {
          vm.waitingPrint = false;
          const WinPrint = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
          );
          WinPrint.document.write(res);
          WinPrint.document.close();
          WinPrint.focus();
          setTimeout(() => {
            WinPrint.print();
            WinPrint.close();
          }, 1000);
        })
        .fail(function() {
          alert("Не может печататься");
          vm.waitingPrint = false;
        });
    }
  }
};
</script>

<style lang="scss">
.application_img {
  .my-c-avatar {
    height: 80px !important;
    width: 70px !important;
  }

  .my-c-avatar img {
    height: 80px !important;
    border-radius: 0;
  }
}
</style>
