<template>
  <div class="p-pegas fontSize">
    <el-row :gutter="20" v-loading="loadProfile">
      <el-col :xs="24" :sm="6">
        <div
          :class="mode ? 'cardtable__day' : 'cardtable__night'"
          class="card-table p-4 mb-5 text-center"
          style="position:relative"
        >
          <div v-if="staff.completion_percentage != 0">
            <div
              class="completion_percentage"
              v-if="
                staff.completion_percentage <= 100 &&
                  staff.completion_percentage >= 75
              "
              style="background-color: #12d229"
            >
              {{ staff.completion_percentage }}%
            </div>
            <div
              class="completion_percentage"
              v-else-if="
                staff.completion_percentage <= 75 &&
                  staff.completion_percentage >= 50
              "
              style="background-color: #a1ffac;"
            >
              {{ staff.completion_percentage }}%
            </div>
            <div
              class="completion_percentage"
              v-else-if="
                staff.completion_percentage <= 50 &&
                  staff.completion_percentage >= 25
              "
              style="background-color: #f1c4c4;"
            >
              {{ staff.completion_percentage }}%
            </div>
            <div
              class="completion_percentage"
              v-else-if="
                staff.completion_percentage <= 25 &&
                  staff.completion_percentage > 0
              "
              style="background-color: #b13939b0;"
            >
              {{ staff.completion_percentage }}%
            </div>
          </div>
          <div class="demo-image__preview">
            <!-- {{ staff.status.code }} -->
            <el-image
              class="my-c-avatar "
              style="width: 100px; height: 100px"
              :class="
                staff && staff.status && staff.status.code == -1
                  ? 'dissmissed_img'
                  : ''
              "
              :src="
                staff.image ? baseUrl + staff.image.path : '/img/avatar.jpeg'
              "
              :preview-src-list="[
                staff.image ? baseUrl + staff.image.path : '/img/avatar.jpeg'
              ]"
            >
            </el-image>
          </div>
          <!-- <img
                        :src="
                            staff.image
                                ? baseUrl + staff.image.path
                                : 'img/avatar.jpeg'
                        "
                        alt=""
                    /> -->
          <div
            :class="mode ? 'text__day2' : 'text__night2'"
            class="mt-4 left_name"
          >
            <h3>{{ staff.name + " " + staff.last_name }}</h3>
          </div>
        </div>
        <div
          :class="mode ? 'cardtable__day' : 'cardtable__night'"
          class="card-table p-4 pt-5 mb-5"
        >
          <div class="itme-info-user co-isir mb-3 pb-2">
            <i
              :class="mode ? 'text__day2' : 'text__night2'"
              class="el-icon-office-building"
            ></i>
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              v-if="staff.company"
            >
              {{ staff.company.name }}
            </span>
          </div>
          <div
            :class="mode ? 'liniya__day' : 'liniya__night'"
            class="linya2 mt-3 mb-3"
          ></div>
          <div class="itme-info-user co-isir mb-3 pb-2">
            <i
              :class="mode ? 'text__day2' : 'text__night2'"
              class="el-icon-school"
            ></i>
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              v-if="staff.branch"
            >
              {{ staff.branch.name }}
            </span>
          </div>
          <div
            :class="mode ? 'liniya__day' : 'liniya__night'"
            class="linya2 mt-3 mb-3"
          ></div>
          <div class="itme-info-user co-isir mb-3 pb-2">
            <i
              :class="mode ? 'text__day2' : 'text__night2'"
              class="el-icon-suitcase"
            ></i>
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              v-if="staff.department"
            >
              {{ staff.department.name }}
            </span>
          </div>
          <div
            :class="mode ? 'liniya__day' : 'liniya__night'"
            class="linya2 mt-3 mb-3"
          ></div>
          <div class="itme-info-user co-isir mb-3 pb-2">
            <i
              :class="mode ? 'text__day2' : 'text__night2'"
              class="el-icon-position"
            ></i>
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              v-if="staff.position"
            >
              {{ staff.position.name }}
            </span>
          </div>
          <div
            :class="mode ? 'liniya__day' : 'liniya__night'"
            class="linya2 mt-3 mb-3"
          ></div>
          <div class="itme-info-user co-isir mb-3 pb-2">
            <i
              :class="mode ? 'text__day2' : 'text__night2'"
              class="el-icon-s-data"
            ></i>
            <span
              :class="mode ? 'text__day2' : 'text__night2'"
              v-if="staff.graphic"
            >
              {{ staff.graphic.name }}
            </span>
          </div>
          <div
            :class="mode ? 'liniya__day' : 'liniya__night'"
            class="linya2 mt-3 mb-3"
          ></div>
          <div class="itme-info-user co-isir mb-3 pb-2">
            <i
              :class="mode ? 'text__day2' : 'text__night2'"
              class="el-icon-paperclip"
            ></i>
            <a :class="mode ? 'text__day2' : 'text__night2'">
              <b v-if="staff.status">
                <span
                  v-if="staff && staff.status && staff.status.code == 1"
                  style="color: #67C23A;"
                >
                  {{ staff.status ? $t("message." + staff.status.name) : null }}
                </span>
                <span
                  v-else-if="staff && staff.status && staff.status.code == -1"
                  style="color:#F56C6C;"
                >
                  {{ staff.status ? $t("message." + staff.status.name) : null }}
                </span>
                <span v-else style="color:#E6A23C">
                  {{ staff.status ? $t("message." + staff.status.name) : null }}
                </span>
              </b>
            </a>
          </div>
          <div
            :class="mode ? 'liniya__day' : 'liniya__night'"
            class="linya2 mt-3 mb-3"
          ></div>
          <div class="itme-info-user co-isir">
            <i
              :class="mode ? 'text__day2' : 'text__night2'"
              class="el-icon-phone-outline"
            ></i>
            <a
              :class="mode ? 'text__day2' : 'text__night2'"
              :href="'tel:+' + staff.phone_number"
            >
              +{{ staff.phone_number }}
            </a>
          </div>
          <div v-can="'profile.showIznPassword'">
            <div
              :class="mode ? 'liniya__day' : 'liniya__night'"
              class="linya2 mt-3 mb-3"
            ></div>
            <div class="itme-info-user co-isir">
              <div class="text-muted">
                {{ $t("message.telegram_password") }}
              </div>
              <div :class="mode ? 'text__day2' : 'text__night2'">
                {{ staff.izn_password }}
              </div>
            </div>
          </div>
          <div
            :class="mode ? 'liniya__day' : 'liniya__night'"
            class="linya2 mt-3 mb-3"
          ></div>
          <div class="itme-info-user co-isir">
            <div class="text-muted">{{ $t("message.last_active") }}</div>
            <div :class="mode ? 'text__day2' : 'text__night2'">
              {{ staff.updated_at }}
            </div>
          </div>
        </div>

        <div
          class="card-table mb-5"
          :class="mode ? 'cardtable__day' : 'cardtable__night'"
        >
          <div class="workers-info-title-za" v-if="zktecoUser">
            <div class="title">
              <span
                class="fa-solid fa-microchip icon__fontawesome "
                style="position:relative; font-size: 20px; margin:0 !important; width: auto;  "
                :class="mode ? 'text__day2' : 'text__night2'"
              ></span>
              {{ $t("message.faceidregistration") }}
            </div>
            <div title="добавить в Zkteco">
              <el-button
                @click="centerDialogVisible = true"
                type="text"
                class="p-0"
              >
                <span
                  class="fa-solid fa-pen-to-square icon__fontawesome "
                  style="position:relative; font-size: 20px; margin:0 !important; width: auto; "
                  :class="mode ? 'text__day2' : 'text__night2'"
                ></span>
              </el-button>
            </div>
          </div>
          <div class="workers-info-title-za" v-else>
            <div
              class="title"
              @click="centerDialogVisible = true"
              style="cursor:pointer"
            >
              <span
                class="fa-solid fa-microchip icon__fontawesome "
                style="position:relative; font-size: 20px; margin:0 !important; width: auto; "
                :class="mode ? 'text__day2' : 'text__night2'"
              ></span>
              {{ $t("message.faceidregistration") }}
            </div>
            <!-- <div title="добавить в Zkteco">
              <el-button @click="centerDialogVisible = true"  type="text" class="p-0"><span  class="fa-solid fa-pen-to-square icon__fontawesome " style="position:relative; font-size: 20px; margin:0 !important; width: auto; color:#848484" ></span></el-button> 
            </div> -->
          </div>
          <!-- <el-button type="text" @click="centerDialogVisible = true" v-if="!zktecoUser">Click to open the Dialog</el-button> -->
          <div v-loading="waitToCreateZktecoUser">
            <div v-if="zktecoUser">
              <div class="itme-info-user co-isir ml-4">
                <p :class="mode ? 'text__day2' : 'text__night2'">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="font-bold"
                  >
                    {{ $t("message.nameFull") }}:</span
                  >
                  {{ zktecoUser.full_name }}
                </p>
              </div>
              <div class="itme-info-user co-isir ml-4">
                <p :class="mode ? 'text__day2' : 'text__night2'">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="font-bold"
                  >
                    ID:</span
                  >
                  {{ zktecoUser.uuid }}
                </p>
              </div>
              <div class="itme-info-user co-isir ml-4">
                <p :class="mode ? 'text__day2' : 'text__night2'">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="font-bold"
                  >
                    UUID:</span
                  >
                  {{ zktecoUser.uuid }}
                </p>
              </div>

              <div class="itme-info-user co-isir ml-4">
                <p :class="mode ? 'text__day2' : 'text__night2'">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="font-bold"
                  >
                    {{ $t("message.password") }}:</span
                  >
                  {{ zktecoUser.password }}
                </p>
              </div>
              <div class="itme-info-user co-isir ml-4">
                <p :class="mode ? 'text__day2' : 'text__night2'">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="font-bold"
                  >
                    {{ $t("message.created_at") }}:</span
                  >
                  {{ zktecoUser.created_at }}
                </p>
              </div>
              <div class="workers-info-title-za">
                <div class="title">
                  <!-- <i class="el-icon-bank-card"></i> Полномочия Zkteco -->
                </div>
                <div>
                  <i
                    title="Удалить пользователя Zkteco"
                    @click="tryToDeleteZktecoUser()"
                    class="el-icon-delete"
                    :class="mode ? 'text__day2' : 'text__night2'"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card-table mb-5"
          :class="mode ? 'cardtable__day' : 'cardtable__night'"
        >
          <div class="workers-info-title-za">
            <div class="title">
              <i class="el-icon-user" :class="mode ? '' : 'text__night2'"></i>
              {{ $t("message.manager") }}
            </div>
          </div>
          <div
            class="boss-info-img"
            v-if="staff.manager"
            style="cursor:pointer"
            @dblclick="pushToProfile(staff.manager)"
          >
            <img
              :src="
                staff.manager
                  ? staff.manager.image
                    ? baseUrl + staff.manager.image.path
                    : '/img/avatar.jpeg'
                  : '/img/avatar.jpeg'
              "
              alt=""
            />
            <div class="boss-info">
              <strong :class="mode ? 'text__day2' : 'text__night2'">
                {{ staff.manager.name + " " + staff.manager.last_name }}
              </strong>
              <div
                class="boss-type text-muted"
                v-if="staff.manager && staff.manager.position"
              >
                {{ staff.manager.position.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="mode ? 'cardtable__day' : 'cardtable__night'"
          class="card-table mb-5"
          v-if="staff.children"
        >
          <div class="workers-info-title-za">
            <div class="title">
              <i
                class="el-icon-share t-100"
                :class="mode ? 'text__day2' : 'text__night2'"
              ></i>
              {{ $t("message.subordinates") }}
            </div>
            <!-- {{staff.children_for_exel.length}} -->
            <span v-can="'staffs.excel'">
              <export-excel
                v-if="staff.children_for_exel.length"
                class="btn excel_btn"
                :data="staff.children_for_exel"
                worksheet="My Worksheet"
                name="users.xls"
                :fields="excel_fields"
              >
                <el-button :size="'mini'">
                  <i class="el-icon-document-delete"></i>
                  {{ $t("message.excel") }}
                </el-button>
              </export-excel>
            </span>
          </div>
          <div
            v-for="(child, index) in staff.children"
            :key="'children' + index"
            style="cursor:pointer"
            @dblclick="pushToProfile(child)"
          >
            <div class="boss-info-img">
              <img
                :src="
                  child.image ? baseUrl + child.image.path : '/img/avatar.jpeg'
                "
              />
              <div
                class="boss-info"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                <strong> {{ child.name + " " + child.last_name }}</strong>
                <div v-if="child.position">
                  <div class="boss-type text-muted">
                    {{ child.position.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="18">
        <el-tabs :class="mode ? '' : 'eltab__night'" v-model="activeName">
          <el-tab-pane label="Home" name="home">
            <homeDashport v-if="staff.id != null" :staff="staff"></homeDashport>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.personal')" name="info">
            <info :staff="staff"></info>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.attendance')" name="attendance">
            <crm-attendance></crm-attendance>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.worksPlace')" name="tab2">
            <crm-work></crm-work>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.file')" name="tab6">
            <crm-document></crm-document>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.document')" name="fiveDocuments">
            <crm-five-documents></crm-five-documents>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.children')" name="tab7">
            <crm-children></crm-children>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.status')" name="tab8">
            <crm-status
              :staff="staff"
              @statusUpdated="fetchStaff()"
            ></crm-status>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.salary')" name="tab9">
            <crm-salary
              :position_id="staff.position_id"
              :branch_id="staff.branch_id"
            ></crm-salary>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.requirements')" name="tab10">
            <crm-requirement :staff="staff"></crm-requirement>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('message.additional_information')"
            name="tab11"
          >
            <crm-userInfo></crm-userInfo>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.inventories')" name="tab12">
            <crm-inventory :staff="staff"></crm-inventory>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.work_history')" name="tab13">
            <crm-work-history></crm-work-history>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      title="Ruxsat etish maydonlari"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div>
        <div
          v-for="location in location_ids"
          :key="'location' + location.id"
          class="mb-2"
        >
          <b class="mr-5" :class="mode ? 'text__day2' : 'text__night2'">{{
            location.name
          }}</b>
          <el-switch v-model="location.is_allow" active-color="green">
          </el-switch>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">{{
          $t("message.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateZktecoApp">{{
          $t("message.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CrmDocument from "./tabs/crm-document.vue";
import CrmFiveDocuments from "./tabs/crm-five-documents.vue";
import CrmAttendance from "./tabs/crm-attendance.vue";
import CrmChildren from "./tabs/crm-children.vue";
import CrmWork from "./tabs/crm-work.vue";
import CrmWorkHistory from "./tabs/crm-work-history.vue";
import Info from "./tabs/info.vue";
import CrmStatus from "./tabs/crm-status.vue";
import CrmRequirement from "./tabs/crm-requirement.vue";
import CrmSalary from "./tabs/crm-salary.vue";
import CrmUserInfo from "./tabs/crm-userInfo.vue";
import HomeDashport from "./tabs/crm-home.vue";
import CrmInventory from "./tabs/crm-inventory.vue";
import form from "@/utils/mixins/form";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "ProfileController",
  mixins: [form],

  data() {
    return {
      activeName: "home",
      centerDialogVisible: false,
      image: "",
      loadProfile: false,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      waitToCreateZktecoUser: false,
      excel_fields: {
        Имя: "name",
        Фамилия: "last_name",
        Должность: "position"
      }
    };
  },
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = "Профиль | " + to.params.id;
      },
      immediate: true
    }
  },

  created() {
    this.fetchStaff();
    this.fetchStaffZkteco();
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({
      staff: "profile/model",
      zktecoUser: "zkteco/zkteco_user",
      createZkteco: "zkteco/createZktecoUser",
      company_device_ids: "zkteco/company_device_ids",
      location_ids: "zkteco/location_ids",
      mode: "MODE"
    }),
    createZkteco: {
      get() {
        return this.$store.getters["zkteco/createZktecoUser"];
      },
      set(boolValue) {
        return this.$store.commit("zkteco/SET_CREATE_ZKTECO_USER", boolValue);
      }
    }
  },
  async mounted() {},
  components: {
    CrmDocument,
    CrmFiveDocuments,
    CrmChildren,
    CrmAttendance,
    Info,
    CrmStatus,
    CrmRequirement,
    CrmUserInfo,
    CrmWork,
    CrmWorkHistory,
    HomeDashport,
    CrmSalary,
    CrmInventory
  },
  methods: {
    ...mapActions({
      show: "profile/show",
      getZktecoUser: "zkteco/getZktecoUser",
      createZktecoUser: "zkteco/createZktecoUser",
      deleteZktecoUser: "zkteco/deleteZktecoUser"
    }),
    fetchStaffZkteco() {
      if (!this.waitToCreateZktecoUser && this.$route.params.id) {
        this.waitToCreateZktecoUser = true;
        this.centerDialogVisible = false;
        this.getZktecoUser(this.$route.params.id)
          .then(res => {
            this.waitToCreateZktecoUser = false;
          })
          .catch(err => {
            this.$alert(err);
            this.waitToCreateZktecoUser = false;
          });
      }
    },
    clickHandler() {
      console.log("hammaaaa");
    },
    fetchStaff() {
      if (!this.loadProfile && this.$route.params.id) {
        this.loadProfile = true;
        this.show(this.$route.params.id)
          .then(res => {
            this.loadProfile = false;
          })
          .catch(err => {
            this.loadProfile = false;
          });
      }
    },
    requestZktecoUser() {
      const form = {
        staff_id: this.$route.params.id,
        device_ids: this.company_device_ids,
        location_ids: this.location_ids
      };
      if (!this.waitToCreateZktecoUser && form) {
        this.waitToCreateZktecoUser = true;
        this.createZktecoUser(form)
          .then(res => {
            this.waitToCreateZktecoUser = false;
            this.$alert(res);
            if (res.status == 201) {
              this.fetchStaffZkteco();
            }
          })
          .catch(err => {
            this.createZkteco = false;
            this.waitToCreateZktecoUser = false;
            this.$alert(err);
          });
      }
    },
    updateZktecoApp(value) {
      if (value) {
        this.$confirm(
          "Вы уверены, что создадите пользователя Zkteco?",
          "Пользователь Zkteco",
          {
            confirmButtonText: "Да",
            cancelButtonText: "Отмен",
            type: "info"
          }
        )
          .then(() => {
            this.requestZktecoUser();
          })
          .catch(() => {
            this.createZkteco = false;
            this.$message({
              type: "info",
              message: "Операция отменена"
            });
          });
      }
      if (!value) {
      }
    },
    tryToDeleteZktecoUser() {
      this.$confirm(
        "Вы уверены, что хотите удалить пользователя Zkteco?",
        "Пользователь Zkteco",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмен",
          type: "warning"
        }
      )
        .then(() => {
          this.requestDeleteZktecoUser();
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена"
          });
        });
    },
    requestDeleteZktecoUser() {
      let zktecoUserId = this.zktecoUser.id;
      if (zktecoUserId) {
        this.deleteZktecoUser(zktecoUserId)
          .then(res => {
            if (res.status == 200) {
              this.fetchStaffZkteco();
            }
            this.$alert(res);
          })
          .catch(err => {
            this.$alert(err);
          });
      }
    },

    comfirmDialog() {
      // console.log(this.location_ids)
    },
    pushToProfile(staff) {
      // console.log(staff);
      let route = this.$router.resolve({
        name: "profile",
        params: {
          id: staff.id
        }
      });

      window.open(route.href, "_blank");
    }
  }
};
</script>
<style lang="scss">
.excel_btn {
  .el-icon-document-delete {
    font-size: initial !important;
  }
}
.completion_percentage {
  position: absolute;
  top: 7px;
  right: 10px;
  display: inline-flex;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 800;
  z-index: 99;
}
.dissmissed_img {
  filter: grayscale(100%) !important;
}
</style>
