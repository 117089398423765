<template>
  <div>
    <client-language v-can="'profile.foreignLanguages.index'"></client-language>

    <clientCertificates
      v-can="'profile.licenses_and_certificates.index'"
    ></clientCertificates>

    <clientHealthInformation
      v-can="'profile.health_information.index'"
    ></clientHealthInformation>
  </div>
</template>
<script>
import ClientCertificates from "./components/client-certificates";
import ClientLanguage from "./components/client-language";
import ClientHealthInformation from "./components/client-health-information";
export default {
  components: {
    ClientCertificates,
    ClientHealthInformation,
    ClientLanguage
  },
  data() {
    return {};
  }
};
</script>
